'use client'

import { isMobile } from 'react-device-detect'
import { useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { useMediaQuery } from '@chakra-ui/react'

const LandingDeviceDetector = () => {
  const router = useRouter()
  const [isDisplayModeStandalone] = useMediaQuery('(display-mode: standalone)')

  // when you install app for the first time App opens in landing page
  useEffect(() => {
    if (isMobile && isDisplayModeStandalone) {
      router.replace('/app')
    }
  }, [isDisplayModeStandalone, router])

  return null
}

export default LandingDeviceDetector
