'use client'

import BaseAppProviders from '#/src/components/BaseAppProviders'
import { PropsWithChildren } from 'react'
import { createLocalStorageManager } from '@chakra-ui/system'
import { landingTheme } from '#/src/app/(landing)/_styles/landingTheme'

export const LandingProvider = ({ children }: PropsWithChildren) => {
  const colorModeManager = createLocalStorageManager('landing-chakra-ui-color-mode')

  return (
    <BaseAppProviders colorModeManager={colorModeManager} theme={landingTheme}>
      {children}
    </BaseAppProviders>
  )
}
